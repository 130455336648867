<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :allowNone="true"
        :organization="purchasesOrder.organization.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!purchasesOrder.id"
        @organizationChanged="onOrganizationChanged"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="purchasesOrder.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="purchasesOrder.organization.id"
        @locationsChanged="
          (locations) => {
            purchasesOrder.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      :label="`${$t('COMMON.SERVICECENTER')}`"
      :placeholder="$t('COMMON.SERVICECENTER')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
    >
      <service-center-selector
        :serviceCenter="
          purchasesOrder.serviceCenter ? purchasesOrder.serviceCenter.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesOrder.organization.id"
        @serviceCenterChanged="
          (serviceCenter) => {
            purchasesOrder.serviceCenter.id = serviceCenter;
            purchasesOrder.establishment.id = null;
            purchasesOrder.cafeteria.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.serviceCenter" />

    <base-input
      :label="`${$t('COMMON.ESTABLISHMENT')}`"
      :placeholder="$t('COMMON.ESTABLISHMENT')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
    >
      <establishment-selector
        :establishment="
          purchasesOrder.establishment ? purchasesOrder.establishment.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesOrder.organization.id"
        :filterServiceCenter="purchasesOrder.serviceCenter.id"
        @establishmentChanged="
          (establishment) => {
            purchasesOrder.establishment.id = establishment;
            purchasesOrder.cafeteria.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.establishment" />

    <base-input
      :label="`${$t('COMMON.CAFETERIA')}`"
      :placeholder="$t('COMMON.CAFETERIA')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
    >
      <cafeteria-selector
        :cafeteria="
          purchasesOrder.cafeteria ? purchasesOrder.cafeteria.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesOrder.organization.id"
        :filterServiceCenter="purchasesOrder.serviceCenter.id"
        :filterEstablishment="purchasesOrder.establishment.id"
        @cafeteriaChanged="
          (cafeteria) => {
            purchasesOrder.cafeteria.id = cafeteria;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.cafeteria" />

    <base-input
      :label="`${$t('PURCHASES_ORDERS.EXPIRATION_TIME')} (*)`"
      :placeholder="$t('PURCHASES_ORDERS.EXPIRATION_TIME')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="purchasesOrder.expiration_time"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.expiration_time" />

    <base-input :label="`${$t(`PURCHASES_ORDERS.SELECT_SUPPLIERS`)} (*)`">
      <supplier-selector
        :allowNone="true"
        :supplier="purchasesOrder.issuer.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesOrder.organization.id"
        :disabled="!!purchasesOrder.id"
        @supplierChanged="
          (supplierId) => {
            purchasesOrder.issuer.id = supplierId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.issuer" />

    <base-input :label="`${$t(`COMMON.DESTINATION_WAREHOUSE`)}`">
      <warehouse-selector
        :allowNone="true"
        :warehouse="
          purchasesOrder.destinationWarehouse
            ? purchasesOrder.destinationWarehouse.id
            : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesOrder.organization.id"
        :disabled="!!purchasesOrder.id"
        @warehouseChanged="
          (warehouseId) => {
            purchasesOrder.destinationWarehouse.id = warehouseId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.destinationWarehouse" />

    <div class="my-3">
      <base-checkbox
        @change="onFormChanged"
        v-model="purchasesOrder.has_no_taxes"
        class="mb-3"
      >
        <span class="form-control-label">
          {{ $t("PURCHASES_ORDERS.EXCLUDING_TAXES") }}
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.has_no_taxes" />
    </div>

    <base-input :label="`${$t(`COMMON.PAYMENT_TERM`)} (*)`">
      <payment-term-selector
        :allowNone="false"
        :paymentTerm="
          purchasesOrder.paymentTerm ? purchasesOrder.paymentTerm.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesOrder.organization.id"
        @paymentTermChanged="
          (paymentTermId) => {
            purchasesOrder.paymentTerm.id = paymentTermId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.paymentTerm" />

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <html-editor v-model="purchasesOrder.excerpt" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <h2 class="mt-5">{{ $t("COMMON.TERMS_CONDITIONS") }}</h2>
    <html-editor
      v-model="purchasesOrder.terms_conditions"
      @change="onFormChanged()"
    >
    </html-editor>
    <validation-error :errors="apiValidationErrors.terms_conditions" />

    <h2 class="mt-5">{{ $t("COMMON.DESCRIPTION") }}</h2>
    <html-editor v-model="purchasesOrder.description" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.description" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          purchasesOrder.id
            ? $t("PURCHASES_ORDERS.EDIT_PURCHASES_ORDER")
            : $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import PaymentTermSelector from "@/components/PaymentTermSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    SupplierSelector,
    ServiceCenterSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    WarehouseSelector,
    PaymentTermSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["purchasesOrderData", "formErrors", "loading"],

  data() {
    return {
      purchasesOrder: null,
    };
  },

  created() {
    let purchasesOrderData = cloneDeep(this.purchasesOrderData);
    purchasesOrderData = this.$fillUserOrganizationData(purchasesOrderData);
    purchasesOrderData = this.$fillUserCafeteriasData(purchasesOrderData);
    if (!purchasesOrderData.id) {
      purchasesOrderData.terms_conditions =
        this.organizationConfig.DEFAULT_PURCHASES_ORDER_TERMS;
      purchasesOrderData.description =
        this.organizationConfig.DEFAULT_PURCHASES_ORDER_DESCRIPTION;
      purchasesOrderData.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_PURCHASES_PAYMENT_TERMS,
      };
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.purchasesOrder = purchasesOrderData;
  },

  computed: {
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  methods: {
    async handleSubmit() {
      let purchasesOrderData = cloneDeep(this.purchasesOrder);
      purchasesOrderData.expiration_time = moment(
        purchasesOrderData.expiration_time
      ).toISOString();
      purchasesOrderData = this.$fillUserOrganizationData(purchasesOrderData);
      purchasesOrderData = this.$fillUserCafeteriasData(purchasesOrderData);
      delete purchasesOrderData.purchasesInvoice;
      if (!purchasesOrderData.serviceCenter.id) {
        delete purchasesOrderData.serviceCenter;
      }
      if (!purchasesOrderData.establishment.id) {
        delete purchasesOrderData.establishment;
      }
      if (!purchasesOrderData.cafeteria.id) {
        delete purchasesOrderData.cafeteria;
      }
      this.$emit("purchasesOrderSubmitted", purchasesOrderData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async onOrganizationChanged(organizationId) {
      if (!organizationId) {
        return;
      }
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        organizationId
      );
      this.purchasesOrder.organization.id = organizationId;
      this.purchasesOrder.allowedLocations = [];
      this.purchasesOrder.issuer.id = null;
      this.purchasesOrder.serviceCenter.id = null;
      this.purchasesOrder.establishment.id = null;
      this.purchasesOrder.cafeteria.id = null;
      this.purchasesOrder.terms_conditions =
        this.organizationConfig.DEFAULT_PURCHASES_ORDER_TERMS;
      this.purchasesOrder.description =
        this.organizationConfig.DEFAULT_PURCHASES_ORDER_DESCRIPTION;
      this.purchasesOrder.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_PURCHASES_PAYMENT_TERMS,
      };
      this.onFormChanged();
      window.scrollTo({ top: 0, behavior: "smooth" });
      swal.close();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    purchasesOrderData(purchasesOrderData) {
      if (purchasesOrderData) {
        this.purchasesOrder = cloneDeep(this.purchasesOrderData);
        this.purchasesOrder = this.$fillUserOrganizationData(
          this.purchasesOrder
        );
        this.purchasesOrder = this.$fillUserCafeteriasData(this.purchasesOrder);
        if (!this.purchasesOrder.organization) {
          this.purchasesOrder.organization = {
            type: "organizations",
            id: null,
          };
        }
        if (!this.purchasesOrder.serviceCenter) {
          this.purchasesOrder.serviceCenter = {
            type: "service-centers",
            id: null,
          };
        }
        if (!this.purchasesOrder.establishment) {
          this.purchasesOrder.establishment = {
            type: "establishments",
            id: null,
          };
        }
        if (!this.purchasesOrder.cafeteria) {
          this.purchasesOrder.cafeteria = {
            type: "cafeterias",
            id: null,
          };
        }
      }
    },
  },
};
</script>
