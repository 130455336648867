var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.purchasesOrder.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesOrder.id},on:{"organizationChanged":_vm.onOrganizationChanged}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.purchasesOrder.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.purchasesOrder.organization.id},on:{"locationsChanged":(locations) => {
          _vm.purchasesOrder.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SERVICECENTERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')}`,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"serviceCenter":_vm.purchasesOrder.serviceCenter ? _vm.purchasesOrder.serviceCenter.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesOrder.organization.id},on:{"serviceCenterChanged":(serviceCenter) => {
          _vm.purchasesOrder.serviceCenter.id = serviceCenter;
          _vm.purchasesOrder.establishment.id = null;
          _vm.purchasesOrder.cafeteria.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"establishment":_vm.purchasesOrder.establishment ? _vm.purchasesOrder.establishment.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesOrder.organization.id,"filterServiceCenter":_vm.purchasesOrder.serviceCenter.id},on:{"establishmentChanged":(establishment) => {
          _vm.purchasesOrder.establishment.id = establishment;
          _vm.purchasesOrder.cafeteria.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')}`,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"cafeteria":_vm.purchasesOrder.cafeteria ? _vm.purchasesOrder.cafeteria.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesOrder.organization.id,"filterServiceCenter":_vm.purchasesOrder.serviceCenter.id,"filterEstablishment":_vm.purchasesOrder.establishment.id},on:{"cafeteriaChanged":(cafeteria) => {
          _vm.purchasesOrder.cafeteria.id = cafeteria;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}}),_c('base-input',{attrs:{"label":`${_vm.$t('PURCHASES_ORDERS.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('PURCHASES_ORDERS.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.purchasesOrder.expiration_time),callback:function ($$v) {_vm.$set(_vm.purchasesOrder, "expiration_time", $$v)},expression:"purchasesOrder.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":`${_vm.$t(`PURCHASES_ORDERS.SELECT_SUPPLIERS`)} (*)`}},[_c('supplier-selector',{attrs:{"allowNone":true,"supplier":_vm.purchasesOrder.issuer.id,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesOrder.organization.id,"disabled":!!_vm.purchasesOrder.id},on:{"supplierChanged":(supplierId) => {
          _vm.purchasesOrder.issuer.id = supplierId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.issuer}}),_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.DESTINATION_WAREHOUSE`)}`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.purchasesOrder.destinationWarehouse
          ? _vm.purchasesOrder.destinationWarehouse.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesOrder.organization.id,"disabled":!!_vm.purchasesOrder.id},on:{"warehouseChanged":(warehouseId) => {
          _vm.purchasesOrder.destinationWarehouse.id = warehouseId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.destinationWarehouse}}),_c('div',{staticClass:"my-3"},[_c('base-checkbox',{staticClass:"mb-3",on:{"change":_vm.onFormChanged},model:{value:(_vm.purchasesOrder.has_no_taxes),callback:function ($$v) {_vm.$set(_vm.purchasesOrder, "has_no_taxes", $$v)},expression:"purchasesOrder.has_no_taxes"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("PURCHASES_ORDERS.EXCLUDING_TAXES"))+" ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.has_no_taxes}})],1),_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.PAYMENT_TERM`)} (*)`}},[_c('payment-term-selector',{attrs:{"allowNone":false,"paymentTerm":_vm.purchasesOrder.paymentTerm ? _vm.purchasesOrder.paymentTerm.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesOrder.organization.id},on:{"paymentTermChanged":(paymentTermId) => {
          _vm.purchasesOrder.paymentTerm.id = paymentTermId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.paymentTerm}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesOrder.excerpt),callback:function ($$v) {_vm.$set(_vm.purchasesOrder, "excerpt", $$v)},expression:"purchasesOrder.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.TERMS_CONDITIONS")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesOrder.terms_conditions),callback:function ($$v) {_vm.$set(_vm.purchasesOrder, "terms_conditions", $$v)},expression:"purchasesOrder.terms_conditions"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.terms_conditions}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.DESCRIPTION")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesOrder.description),callback:function ($$v) {_vm.$set(_vm.purchasesOrder, "description", $$v)},expression:"purchasesOrder.description"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.description}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.purchasesOrder.id ? _vm.$t("PURCHASES_ORDERS.EDIT_PURCHASES_ORDER") : _vm.$t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }